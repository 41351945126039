/**** Colors (recommended use HEX colors) ****/
$main-color: #170324;
$default-color:#ffffff;
$dark-color: #000;
$default-text-color:#1f0018;

$body-bg-gradient-color-1: #4d004d;
$body-bg-gradient-color-2:  #ffb3ff;

$sidebar-bg-color:#210433;
$sidebar-item-active-bg-color: #9313E5;


$primary-color: #210433;
$success-color: #2d922d;
$info-color: #248dad;
$warning-color: #f79a17;
$danger-color: #bf1725;
