@import "skins/purple";

$body-bg-color: #fff;
$text-color: #242424;
$gray-dark: #292b2c;
$gray: #555;
$gray-light: #ccc;
$gray-lighter: #eceeef;

$table-bg-active: rgba(#000,.075);
$table-border-width: 1px;
$table-border-color: $gray-lighter;

/**** App roots ****/
$assets-root: '~/assets/';
$images-root: $assets-root + 'img/';
$fonts-root: $assets-root + 'fonts/';


/**** General ****/
$font-family: 'Roboto', sans-serif;


/**** Navbar ****/
$navbar-text-color: $default-color;
$navbar-height: 60px;


/**** Sidebar ****/
$sidebar-width: 220px;



/**** Progress ****/
$progress-border-radius: 0;
$progress-height: 20px;


/**** Fonts ****/
$font-thin: 100;
$font-light: 300;
$font-normal: 400;
$font-bold: 500;
$font-bolder: 700;
$font-ultraBold: 900;


/**** Social icons ****/
$facebook-color: #3b5998;
$twitter-color: #55acee;
$instagram-color: #8a3ab9;
$pinterest-color: #c92228;
$google-color: #dd4b39;
$linkedin-color: #0177B5;
$github-color: #6b6b6b;
$stackoverflow-color: #2F96E8;
$dribble-color: #F26798;
$behace-color: #0093FA;

//--------for prevent awesome-bootstrap-checkbox errors---------
//@import "../../../../node_modules/bootstrap/scss/variables";
//@import "../../../../node_modules/bootstrap/scss/mixins";
$input-border-color: rgba(rgb(73, 73, 73),.15);
$enable-transitions: true;
$input-color: $gray;
$input-bg-disabled: $gray-lighter;
$brand-primary: $primary-color;
$brand-success: $success-color;
$brand-info: $info-color;
$brand-warning: $warning-color;
$brand-danger: $danger-color;
$brand-inverse: $gray-dark;




//Set variables to be accessible for JS
@import "../../../../node_modules/sass-to-js/sass/sass-to-js";

$variablesMap: (
  main-color: $main-color,
  default-color: $default-color,
  dark-color: $dark-color,
  primary-color: $primary-color,
  success-color: $success-color,
  warning-color: $warning-color,
  danger-color: $danger-color,
  info-color: $info-color,
  sidebar-bg-color: $sidebar-bg-color,
  gray: $gray,
  gray-light: $gray-light
);

body::after {
    content: sassToJs($variablesMap);
    display: none;
}

